export default function Plate(props) {

    return (
        <svg width="30" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.2416 20.1429H17.7V26.3571H10.3041L12.2991 21.444C12.6141 20.6607 13.3819 20.1429 14.2416 20.1429ZM20.85 26.3571V20.1429H26.1787C26.8153 20.1429 27.4191 20.4277 27.8194 20.9196L32.2294 26.3571H20.85ZM37.6631 26.4219L31.1006 18.3304C29.9062 16.8545 28.095 16 26.1787 16H14.2416C11.6625 16 9.34594 17.5471 8.39437 19.9033L5.66437 26.642C4.1025 27.2504 3 28.7458 3 30.5V37.75C3 38.8958 3.93844 39.8214 5.1 39.8214H7.28531C7.78406 42.7603 10.3762 45 13.5 45C16.6237 45 19.2159 42.7603 19.7147 39.8214H28.2853C28.7841 42.7603 31.3762 45 34.5 45C37.6237 45 40.2159 42.7603 40.7147 39.8214H42.9C44.0616 39.8214 45 38.8958 45 37.75V34.6429C45 30.4223 41.7975 26.9397 37.6631 26.4219ZM37.4728 39.8214C37.0397 41.0254 35.8716 41.8929 34.5 41.8929C33.1284 41.8929 31.9603 41.0254 31.5272 39.8214C31.4091 39.4978 31.35 39.1482 31.35 38.7857C31.35 37.0703 32.7609 35.6786 34.5 35.6786C36.2391 35.6786 37.65 37.0703 37.65 38.7857C37.65 39.1482 37.5844 39.4978 37.4728 39.8214V39.8214ZM13.5 41.8929C12.1284 41.8929 10.9603 41.0254 10.5272 39.8214C10.4091 39.4978 10.35 39.1482 10.35 38.7857C10.35 37.0703 11.7609 35.6786 13.5 35.6786C15.2391 35.6786 16.65 37.0703 16.65 38.7857C16.65 39.1482 16.5844 39.4978 16.4728 39.8214C16.0397 41.0254 14.8716 41.8929 13.5 41.8929Z" fill="url(#paint0_linear_6_59)"/>
        <path d="M11.5 21.0834C14.1463 21.0834 16.5421 20.0107 18.2764 18.2764C20.0106 16.5422 21.0833 14.1464 21.0833 11.5C21.0833 8.85368 20.0106 6.45785 18.2764 4.72358C16.5421 2.98934 14.1463 1.91669 11.5 1.91669C8.85362 1.91669 6.45778 2.98934 4.72352 4.72358C2.98928 6.45785 1.91663 8.85368 1.91663 11.5C1.91663 14.1464 2.98928 16.5422 4.72352 18.2764C6.45778 20.0107 8.85362 21.0834 11.5 21.0834Z" fill="white" stroke="white" stroke-width="2" stroke-linejoin="round"/>
        <path d="M7.66663 11.5L10.5416 14.375L16.2916 8.625" stroke="url(#paint1_linear_6_59)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_6_59" x1="40.7045" y1="18.9" x2="-5.86549" y2="44.5005" gradientUnits="userSpaceOnUse">
        <stop offset="0.0756783" stop-color={props.color}/>
        <stop offset="0.678126" stop-color="#949494"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6_59" x1="16.4999" y1="8.5" x2="7.49988" y2="13.5" gradientUnits="userSpaceOnUse">
        <stop stop-color={props.color}/>
        <stop offset="1" stop-color="#949494"/>
        </linearGradient>
        </defs>
        </svg>
    )
}