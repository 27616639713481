export default function Rate(props) {

    return (
        <svg width="30" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.4375 3.9375L38.9981 6.59063L41.6493 25.1531L24.4143 42.3881C24.0627 42.7396 23.5859 42.9371 23.0887 42.9371C22.5915 42.9371 22.1147 42.7396 21.7631 42.3881L3.20059 23.8256C2.84908 23.474 2.65161 22.9972 2.65161 22.5C2.65161 22.0028 2.84908 21.526 3.20059 21.1744L20.4375 3.9375ZM25.74 19.8488C26.0882 20.1969 26.5017 20.4731 26.9567 20.6615C27.4117 20.8498 27.8994 20.9467 28.3919 20.9467C28.8843 20.9466 29.372 20.8495 29.8269 20.6609C30.2819 20.4724 30.6952 20.1961 31.0434 19.8478C31.3916 19.4995 31.6677 19.0861 31.8561 18.6311C32.0445 18.176 32.1414 17.6884 32.1413 17.1959C32.1412 16.7034 32.0441 16.2158 31.8556 15.7609C31.6671 15.3059 31.3907 14.8925 31.0425 14.5444C30.6942 14.1962 30.2807 13.9201 29.8257 13.7317C29.3707 13.5433 28.883 13.4464 28.3905 13.4465C27.396 13.4466 26.4422 13.8419 25.739 14.5453C25.0359 15.2487 24.6409 16.2026 24.6411 17.1972C24.6413 18.1918 25.0366 19.1456 25.74 19.8488Z" fill="url(#paint0_linear_6_69)"/>
        <g clip-path="url(#clip0_6_69)">
        <path d="M25.1574 31.8983C22.1381 35.0608 17.1271 35.1769 13.9646 32.1575C10.8021 29.1382 10.686 24.1272 13.7053 20.9647C16.7246 17.8022 21.7357 17.6861 24.8982 20.7054C28.0607 23.7248 28.1767 28.7358 25.1574 31.8983ZM24.0122 30.8049C25.1721 29.59 25.8019 27.9641 25.763 26.2848C25.7241 24.6056 25.0197 23.0106 23.8048 21.8507C22.5899 20.6907 20.964 20.061 19.2847 20.0999C17.6055 20.1388 16.0105 20.8431 14.8505 22.058C13.6906 23.273 13.0609 24.8989 13.0997 26.5781C13.1386 28.2574 13.843 29.8524 15.0579 31.0123C16.2728 32.1722 17.8988 32.802 19.578 32.7631C21.2573 32.7242 22.8523 32.0199 24.0122 30.8049ZM18.6632 29.529L21.67 26.3796C21.7424 26.3037 21.7818 26.2021 21.7794 26.0971C21.7769 25.9922 21.7329 25.8925 21.657 25.82C21.5811 25.7475 21.4794 25.7081 21.3745 25.7106C21.2695 25.713 21.1698 25.757 21.0973 25.833L18.9106 28.1234C18.5482 28.503 18.0497 28.7232 17.5249 28.7353C17.0002 28.7475 16.4921 28.5507 16.1124 28.1882C15.7327 27.8257 15.5126 27.3273 15.5005 26.8025C15.4883 26.2777 15.6851 25.7696 16.0476 25.39L16.5943 24.8174L15.4491 23.724L16.5424 22.5788L17.6876 23.6722L19.0543 22.2407L20.1996 23.334L17.1928 26.4833C17.1203 26.5593 17.081 26.6609 17.0834 26.7658C17.0858 26.8708 17.1298 26.9705 17.2058 27.043C17.2817 27.1155 17.3833 27.1548 17.4883 27.1524C17.5932 27.15 17.6929 27.106 17.7654 27.03L19.9521 24.7396C20.3146 24.3599 20.8131 24.1398 21.3378 24.1277C21.8626 24.1155 22.3707 24.3123 22.7504 24.6748C23.13 25.0373 23.3501 25.5357 23.3623 26.0605C23.3744 26.5852 23.1776 27.0933 22.8152 27.473L22.2685 28.0456L23.4137 29.139L22.3203 30.2842L21.1751 29.1908L19.8084 30.6223L18.6632 29.529Z" fill="white"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_6_69" x1="37.6609" y1="7.83746" x2="-11.557" y2="26.5182" gradientUnits="userSpaceOnUse">
        <stop offset="0.0756783" stop-color={props.color} />
        <stop offset="0.678126" stop-color="#949494"/>
        </linearGradient>
        <clipPath id="clip0_6_69">
        <rect width="19" height="19" fill="white" transform="translate(6 26.7426) rotate(-46.3268)"/>
        </clipPath>
        </defs>
        </svg>
    )
}