export default function ParkIn(props) {

    return (
        <svg width={props.width} height="49" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.7769 23.2857H17.4V29.7143H9.65188L11.7419 24.6317C12.0719 23.8214 12.8763 23.2857 13.7769 23.2857ZM20.7 29.7143V23.2857H26.2825C26.9494 23.2857 27.5819 23.5804 28.0013 24.0893L32.6213 29.7143H20.7ZM38.3138 29.7812L31.4388 21.4107C30.1875 19.8839 28.29 19 26.2825 19H13.7769C11.075 19 8.64812 20.6004 7.65125 23.0379L4.79125 30.0089C3.155 30.6384 2 32.1853 2 34V41.5C2 42.6853 2.98312 43.6429 4.2 43.6429H6.48938C7.01188 46.683 9.7275 49 13 49C16.2725 49 18.9881 46.683 19.5106 43.6429H28.4894C29.0119 46.683 31.7275 49 35 49C38.2725 49 40.9881 46.683 41.5106 43.6429H43.8C45.0169 43.6429 46 42.6853 46 41.5V38.2857C46 33.9196 42.645 30.317 38.3138 29.7812ZM38.1144 43.6429C37.6606 44.8884 36.4369 45.7857 35 45.7857C33.5631 45.7857 32.3394 44.8884 31.8856 43.6429C31.7619 43.308 31.7 42.9464 31.7 42.5714C31.7 40.7969 33.1781 39.3571 35 39.3571C36.8219 39.3571 38.3 40.7969 38.3 42.5714C38.3 42.9464 38.2312 43.308 38.1144 43.6429V43.6429ZM13 45.7857C11.5631 45.7857 10.3394 44.8884 9.88562 43.6429C9.76187 43.308 9.7 42.9464 9.7 42.5714C9.7 40.7969 11.1781 39.3571 13 39.3571C14.8219 39.3571 16.3 40.7969 16.3 42.5714C16.3 42.9464 16.2313 43.308 16.1144 43.6429C15.6606 44.8884 14.4369 45.7857 13 45.7857Z" fill="url(#paint0_linear_6_63)"/>
        <g clip-path="url(#clip0_6_63)">
        <rect x="2" y="4" width="15" height="15" fill="url(#paint1_linear_6_63)"/>
        </g>
        <path d="M9 15H10.5C11.4283 15 12.3185 14.6313 12.9749 13.9749C13.6313 13.3185 14 12.4283 14 11.5C14 10.5717 13.6313 9.6815 12.9749 9.02513C12.3185 8.36875 11.4283 8 10.5 8H7V18H9V15ZM2 4H18C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H2C1.73478 22 1.48043 21.8946 1.29289 21.7071C1.10536 21.5196 1 21.2652 1 21V5C1 4.73478 1.10536 4.48043 1.29289 4.29289C1.48043 4.10536 1.73478 4 2 4ZM9 10H10.5C10.8978 10 11.2794 10.158 11.5607 10.4393C11.842 10.7206 12 11.1022 12 11.5C12 11.8978 11.842 12.2794 11.5607 12.5607C11.2794 12.842 10.8978 13 10.5 13H9V10Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_6_63" x1="41.5" y1="22" x2="-7" y2="49" gradientUnits="userSpaceOnUse">
        <stop offset="0.0756783" stop-color={props.color}/>
        <stop offset="0.678126" stop-color="#949494"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6_63" x1="15.4659" y1="5.5" x2="-3.46495" y2="12.6856" gradientUnits="userSpaceOnUse">
        <stop offset="0.0756783" stop-color={props.color}/>
        <stop offset="0.678126" stop-color="#949494"/>
        </linearGradient>
        <clipPath id="clip0_6_63">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}
