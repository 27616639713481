export default function List(props) {

    return (
        <svg width="30" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6_15)">
        <path d="M20.625 39.375C20.625 38.6291 20.3287 37.9137 19.8012 37.3863C19.2738 36.8588 18.5584 36.5625 17.8125 36.5625C17.0666 36.5625 16.3512 36.8588 15.8238 37.3863C15.2963 37.9137 15 38.6291 15 39.375H5.625C5.12772 39.375 4.65081 39.1775 4.29917 38.8258C3.94754 38.4742 3.75 37.9973 3.75 37.5V7.5C3.75 7.00272 3.94754 6.52581 4.29917 6.17417C4.65081 5.82254 5.12772 5.625 5.625 5.625H15C15 6.37092 15.2963 7.08629 15.8238 7.61374C16.3512 8.14118 17.0666 8.4375 17.8125 8.4375C18.5584 8.4375 19.2738 8.14118 19.8012 7.61374C20.3287 7.08629 20.625 6.37092 20.625 5.625H39.375C39.8723 5.625 40.3492 5.82254 40.7008 6.17417C41.0525 6.52581 41.25 7.00272 41.25 7.5V17.8125C40.0068 17.8125 38.8145 18.3064 37.9354 19.1854C37.0564 20.0645 36.5625 21.2568 36.5625 22.5C36.5625 23.7432 37.0564 24.9355 37.9354 25.8146C38.8145 26.6936 40.0068 27.1875 41.25 27.1875V37.5C41.25 37.9973 41.0525 38.4742 40.7008 38.8258C40.3492 39.1775 39.8723 39.375 39.375 39.375H20.625ZM17.8125 19.6875C18.5584 19.6875 19.2738 19.3912 19.8012 18.8637C20.3287 18.3363 20.625 17.6209 20.625 16.875C20.625 16.1291 20.3287 15.4137 19.8012 14.8863C19.2738 14.3588 18.5584 14.0625 17.8125 14.0625C17.0666 14.0625 16.3512 14.3588 15.8238 14.8863C15.2963 15.4137 15 16.1291 15 16.875C15 17.6209 15.2963 18.3363 15.8238 18.8637C16.3512 19.3912 17.0666 19.6875 17.8125 19.6875ZM17.8125 30.9375C18.5584 30.9375 19.2738 30.6412 19.8012 30.1137C20.3287 29.5863 20.625 28.8709 20.625 28.125C20.625 27.3791 20.3287 26.6637 19.8012 26.1363C19.2738 25.6088 18.5584 25.3125 17.8125 25.3125C17.0666 25.3125 16.3512 25.6088 15.8238 26.1363C15.2963 26.6637 15 27.3791 15 28.125C15 28.8709 15.2963 29.5863 15.8238 30.1137C16.3512 30.6412 17.0666 30.9375 17.8125 30.9375Z" fill="url(#paint0_linear_6_15)"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_6_15" x1="37.4148" y1="9" x2="-8.55448" y2="28.3872" gradientUnits="userSpaceOnUse">
        <stop offset="0.0756783" stop-color={props.color}/>
        <stop offset="0.678126" stop-color="#949494"/>
        </linearGradient>
        <clipPath id="clip0_6_15">
        <rect width="45" height="45" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}