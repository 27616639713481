import React, { useEffect, useState } from 'react';
import PlatesView from './PlatesView';
import Spinner from '../../../../shared/Spinner';
import ticketServices from '../../../../services/ticket-service';
import parkingService from '../../../../services/parking-service';


export default function Plates(props) {
    const [plates, setPlates] = useState([]);
    const [fiteredPlates, setFiteredPlates] = useState([]);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        getPlates();
    }, [])

    const getPlates = async()=>{
        setSpinner(true);
        const res = await parkingService.getParkingsByCity({city_id: props.inputField.city});
        setPlates(res.data);
        setFiteredPlates(res.data);
        setSpinner(false);
    }

    const handleSearch = (value) => {
        let filteredRows = plates.filter((row) => {
            return row.plate.toLowerCase().includes(value.toLowerCase())
        });
        if(!filteredRows.length){
            filteredRows = [{
                plate: value.toUpperCase()
            }]
            props.setInputField({...props.inputField, plate: value.toUpperCase()});
        }else{
            props.setInputField({...props.inputField, plate: null});
        }
        setFiteredPlates(filteredRows);
    }

    return (
        <>
            <PlatesView
                org={props.org}
                literals={props.literals}
                plates={fiteredPlates}
                inputField={props.inputField}

                handleSearch={(e) => handleSearch(e.target.value)}
                handlePlateSelect={(e)=> props.setInputField({...props.inputField, ...e})}
            />
            <Spinner
                spinner = {spinner}
            />
        </>
    );
}