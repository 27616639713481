export default function Percantage(props) {

    return (
        <svg width="30" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6_24)">
        <path d="M9.37501 13.5C9.37501 12.406 9.80961 11.3568 10.5832 10.5832C11.3568 9.80961 12.406 9.37501 13.5 9.37501H15.375C16.4642 9.37439 17.5089 8.94301 18.2813 8.17501L19.5938 6.86251C19.9771 6.47701 20.4329 6.17109 20.9348 5.96234C21.4368 5.75358 21.9751 5.64612 22.5188 5.64612C23.0624 5.64612 23.6007 5.75358 24.1027 5.96234C24.6047 6.17109 25.0604 6.47701 25.4438 6.86251L26.7563 8.17501C27.5286 8.94301 28.5733 9.37439 29.6625 9.37501H31.5375C32.6315 9.37501 33.6807 9.80961 34.4543 10.5832C35.2279 11.3568 35.6625 12.406 35.6625 13.5V15.375C35.6631 16.4642 36.0945 17.5089 36.8625 18.2813L38.175 19.5938C38.5605 19.9771 38.8664 20.4329 39.0752 20.9348C39.2839 21.4368 39.3914 21.9751 39.3914 22.5188C39.3914 23.0624 39.2839 23.6007 39.0752 24.1027C38.8664 24.6047 38.5605 25.0604 38.175 25.4438L36.8625 26.7563C36.0945 27.5286 35.6631 28.5733 35.6625 29.6625V31.5375C35.6625 32.6315 35.2279 33.6807 34.4543 34.4543C33.6807 35.2279 32.6315 35.6625 31.5375 35.6625H29.6625C28.5733 35.6631 27.5286 36.0945 26.7563 36.8625L25.4438 38.175C25.0604 38.5605 24.6047 38.8664 24.1027 39.0752C23.6007 39.2839 23.0624 39.3914 22.5188 39.3914C21.9751 39.3914 21.4368 39.2839 20.9348 39.0752C20.4329 38.8664 19.9771 38.5605 19.5938 38.175L18.2813 36.8625C17.5089 36.0945 16.4642 35.6631 15.375 35.6625H13.5C12.406 35.6625 11.3568 35.2279 10.5832 34.4543C9.80961 33.6807 9.37501 32.6315 9.37501 31.5375V29.6625C9.37439 28.5733 8.94301 27.5286 8.17501 26.7563L6.86251 25.4438C6.47701 25.0604 6.17109 24.6047 5.96234 24.1027C5.75358 23.6007 5.64612 23.0624 5.64612 22.5188C5.64612 21.9751 5.75358 21.4368 5.96234 20.9348C6.17109 20.4329 6.47701 19.9771 6.86251 19.5938L8.17501 18.2813C8.94301 17.5089 9.37439 16.4642 9.37501 15.375V13.5Z" fill="url(#paint0_linear_6_24)"/>
        <path d="M27.1875 28.125C27.7053 28.125 28.125 27.7053 28.125 27.1875C28.125 26.6697 27.7053 26.25 27.1875 26.25C26.6697 26.25 26.25 26.6697 26.25 27.1875C26.25 27.7053 26.6697 28.125 27.1875 28.125Z" fill="white" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.8125 18.75C18.3303 18.75 18.75 18.3303 18.75 17.8125C18.75 17.2947 18.3303 16.875 17.8125 16.875C17.2947 16.875 16.875 17.2947 16.875 17.8125C16.875 18.3303 17.2947 18.75 17.8125 18.75Z" fill="white" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.875 28.125L28.125 16.875" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_6_24" x1="35.9402" y1="9.02065" x2="-6.64829" y2="25.1859" gradientUnits="userSpaceOnUse">
        <stop offset="0.0756783" stop-color={props.color}/>
        <stop offset="0.678126" stop-color="#949494"/>
        </linearGradient>
        <clipPath id="clip0_6_24">
        <rect width="45" height="45" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}
