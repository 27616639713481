export default function ParkOut(props) {

    return (
        <svg width="44" height="49" viewBox="0 0 44 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.7769 23.2857H15.4V29.7143H7.65188L9.74188 24.6317C10.0719 23.8214 10.8763 23.2857 11.7769 23.2857ZM18.7 29.7143V23.2857H24.2825C24.9494 23.2857 25.5819 23.5804 26.0013 24.0893L30.6213 29.7143H18.7ZM36.3138 29.7812L29.4388 21.4107C28.1875 19.8839 26.29 19 24.2825 19H11.7769C9.075 19 6.64812 20.6004 5.65125 23.0379L2.79125 30.0089C1.155 30.6384 0 32.1853 0 34V41.5C0 42.6853 0.983125 43.6429 2.2 43.6429H4.48938C5.01188 46.683 7.7275 49 11 49C14.2725 49 16.9881 46.683 17.5106 43.6429H26.4894C27.0119 46.683 29.7275 49 33 49C36.2725 49 38.9881 46.683 39.5106 43.6429H41.8C43.0169 43.6429 44 42.6853 44 41.5V38.2857C44 33.9196 40.645 30.317 36.3138 29.7812ZM36.1144 43.6429C35.6606 44.8884 34.4369 45.7857 33 45.7857C31.5631 45.7857 30.3394 44.8884 29.8856 43.6429C29.7619 43.308 29.7 42.9464 29.7 42.5714C29.7 40.7969 31.1781 39.3571 33 39.3571C34.8219 39.3571 36.3 40.7969 36.3 42.5714C36.3 42.9464 36.2312 43.308 36.1144 43.6429V43.6429ZM11 45.7857C9.56313 45.7857 8.33937 44.8884 7.88562 43.6429C7.76187 43.308 7.7 42.9464 7.7 42.5714C7.7 40.7969 9.17813 39.3571 11 39.3571C12.8219 39.3571 14.3 40.7969 14.3 42.5714C14.3 42.9464 14.2313 43.308 14.1144 43.6429C13.6606 44.8884 12.4369 45.7857 11 45.7857Z" fill="url(#paint0_linear_6_71)"/>
        <path d="M14 9.75V7.875C14 7.37772 13.8084 6.90081 13.4675 6.54917C13.1265 6.19754 12.664 6 12.1818 6H5.81818C5.33597 6 4.87351 6.19754 4.53253 6.54917C4.19156 6.90081 4 7.37772 4 7.875V19.125C4 19.6223 4.19156 20.0992 4.53253 20.4508C4.87351 20.8025 5.33597 21 5.81818 21H12.1818C12.664 21 13.1265 20.8025 13.4675 20.4508C13.8084 20.0992 14 19.6223 14 19.125V17.25H6.52525V9.75H14Z" fill="white" stroke="white" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 13.5H20M20 13.5L17.6429 10M20 13.5L17.6429 17" stroke={props.color} stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_6_71" x1="39.5" y1="22" x2="-9" y2="49" gradientUnits="userSpaceOnUse">
        <stop offset="0.0756783" stop-color={props.color}/>
        <stop offset="0.678126" stop-color="#949494"/>
        </linearGradient>
        </defs>
        </svg>
    )
}