import React, {useState, useEffect} from 'react';
import mainService from '../../../services/main-service';
import SnackAlert from '../../../Common/Alerts';
import Spinner from '../../../Common/Spinner';
import { Layout } from '../../../components/SidebarHeaderWrapper';
import AddVistor from './AddVisitor';
import cityServices from '../../../services/city-service';
import parkingService from '../../../services/parking-service';

export default function Visitors(props) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState('');
  const [inputField, setInputField] = useState({});
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [rateTypes, setRateTypes] = useState([]);
  const [selectedRateType, setSelectedRateType] = useState(null);
  const [steps, setSteps] = useState(0);
  const [rateCycle, setRateCycle] = useState([]);
  const [stepData, setStepData] = useState([]);

  useEffect(()=>{
    getVisitorZone();
  },[])

  const getVisitorZone = async()=>{
    setShowSpinner(true);
    const res = await cityServices.getTenantAndVisitorZones({org_id: props.org._id});
    setZones(res.data)
    setShowSpinner(false);
    if(res.data.length == 1)
    onSelectedZone(res.data[0])
  }

  const handleChange = (e)=> {
    setInputField({...inputField, [e.target.name] : e.target.value});
  }

  const onSelectedZone = async (e)=>{
    setSelectedZone(e);
    const res = await mainService.getRateById({id: e._id});
    setRateTypes(res.data);
    if(res.data.length == 1)
    onSelectedRateType(res.data[0])
  }
  
  const onSelectedRateType = async (e)=>{
    setSelectedRateType(e);
    const res = await mainService.getRateSteps({id: e._id, rate_type: e.rate_type, org: props.org._id})
    let arr = [...res.data];
    let result = [];
    arr.map(x=>{
      result.filter(y=>y.time == x.time).length == 0 && result.push(x);
    })
    setRateCycle(result);
    if(result.length > 0){
      var data = result.map(function(item) {
        return ((item['total']/100).toFixed(2));
      });
      setStepData(data)
    }else{
      setAlertMessage(res.data.msg);
      setSeverity("error");
      setShowAlert(true);
    }
  }

  const handleSubmit = async(e)=>{
    e.preventDefault();
    setShowSpinner(true);
    let user = JSON.parse(sessionStorage.getItem('userLogged'))
    let body = {
      amount : rateCycle[steps].total,
      plate: inputField.plate,
      user: user.result._id,
      added_by: user.result._id,
      zone: selectedZone._id,
      city: selectedZone.city_id._id,
      from: rateCycle[steps].current_time,
      to: rateCycle[steps].time_desc,
      service_fee: rateCycle[steps].service_fee,
      rate: selectedRateType._id,
      org: props.org._id,
      tenant_visitor_zone: true,
      no_of_visitors: user.result.no_of_visitors
    }
    const res = await parkingService.buyParking(body);
    if(res.data.msg){
      setAlertMessage(res.data.msg);
      setSeverity("error");
      setShowAlert(true);
    }else{
      setAlertMessage('Visitor Pass Purchased');
      setSeverity("success");
      setShowAlert(true);
      window.location.reload();
    }
    setShowSpinner(false);
  }

  return (
    <>
        <Layout literals = {props.literals} org={props.org}>
            <AddVistor 
                inputField = {inputField}
                literals = {props.literals}
                zones = {zones}
                selectedZone = {selectedZone}
                rateTypes = {rateTypes}
                selectedRateType = {selectedRateType}
                rateCycle = {rateCycle}
                steps = {steps}
                
                onSelectedZone = {(e)=>onSelectedZone(e)}
                onSelectedRateType = {(e)=>onSelectedRateType(e)}
                setSteps = {(e)=>setSteps(e)}
                handleChange = {(e)=>handleChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <SnackAlert
                alertMessage = {alertMessage}
                showAlert = {showAlert}
                severity = {severity}
                
                closeAlert = {()=>setShowAlert(!showAlert)}
            />
            <Spinner
                spinner = {showSpinner}
            />
        </Layout>
    </>
  );
}